import type { Blank, Product } from '@drop-party/sanity';
import type { Country } from 'country-region-data';
import PostalAddress from 'i18n-postal-address';
import { flow, groupBy, isNil, mapValues, sumBy } from 'lodash/fp';
import type { Address as ShippoAddress, Parcel } from 'shippo';
import type { TaxLineItem, TaxParams } from 'taxjar/dist/types/paramTypes';

import type { LineItem } from 'models/orders';
import { getSkuName } from 'models/products';
import type { ShippingAddress } from 'utils';
import type { PassportItem } from 'vendors/passport';

export const getFormattedAddress = (shippingAddress: ShippingAddress, countries: Country[]): string[][] => new PostalAddress()
	.addFormat({
		country: 'US',
		parser:  'array',
		type:    'default',
		format:  [
			[
				'honorific',
				'firstName',
				'secondName',
				'lastName',
			],
			['companyName'],
			['address1'],
			['address2'],
			[
				{
					attribute:  'city',
					transforms: [(string): string => `${string},`],
				},
				'state',
				'postalCode',
			],
		],
	})
	.setAddress1(shippingAddress.addressLine[0] ?? '')
	.setAddress2(shippingAddress.addressLine[1] ?? '')
	.setCountry(countries.find(({ countryShortCode }) => countryShortCode === shippingAddress.country)?.countryName ?? '')
	.setCity(shippingAddress.city)
	.setRegion(shippingAddress.region)
	.setPostalCode(shippingAddress.postalCode)
	.setState(shippingAddress.region)
	.setProvince(shippingAddress.region)
	.setRepublic(shippingAddress.region)
	.setFormat({ country: shippingAddress.country })
	.output('array') as string[][];

export const getPassportItemFromLineItem = (
	{ country: origin }: ShippingAddress,
	productsById: { [id: string]: Pick<Product, 'amount' | 'hsCode' | 'label' | 'mass_unit' | 'weight'> & { blank?: Pick<Blank, 'hsCode' | 'mass_unit' | 'weight'> } }
) => (
	{
		quantity,
		sku,
		sku: { product },
	}: LineItem
): PassportItem => {
	const {
		[product]: {
			amount,
			hsCode,
			label: description,
			mass_unit: massUnitProduct,
			weight: weightProduct,
			blank: {
				hsCode: hsCodeBlank = undefined,
				mass_unit: massUnitBlank = undefined,
				weight: weightBlank = undefined,
			} = {},
		},
	} = productsById;

	const units_weight = massUnitProduct ?? massUnitBlank;
	const weight = weightProduct ?? weightBlank;

	return {
		description,
		origin,
		quantity,
		hs_code: hsCode ?? hsCodeBlank,
		sku:     getSkuName(sku),
		value:   (quantity * amount) / 100,
		...isNil(units_weight) || isNil(weight)
			? {
				units_weight: 'g',
				weight:       0,
			}
			: {
				units_weight,
				weight: quantity * weight,
			},
	};
};

export const getShippoAddressFromShippingAddress = ({
	city,
	country,
	addressLine: [street1, street2, street3] = [],
	postalCode: zip,
	recipient: name,
	region: state,
}: ShippingAddress): Required<Pick<ShippoAddress, 'city' | 'country' | 'name' | 'state' | 'street1' | 'street2' | 'street3' | 'zip'>> => ({
	city,
	country,
	name,
	state,
	street1,
	street2,
	street3,
	zip,
});

export const getTaxjarTaxForOrderToAddressParams = ({
	addressLine: [to_street] = [],
	city: to_city,
	country: to_country,
	postalCode: to_zip,
	region: to_state,
}: ShippingAddress): Required<Pick<TaxParams, 'to_city' | 'to_country' | 'to_state' | 'to_street' | 'to_zip'>> => ({
	to_city,
	to_country,
	to_state,
	to_street,
	to_zip,
});

export const getTaxjarLineItemFromLineItem = (productsById: { [id: string]: Pick<Product, 'amount' | 'productTaxCode'> & { blank?: Pick<Blank, 'productTaxCode'> } }) => ({ quantity, sku, sku: { product } }: LineItem): Pick<TaxLineItem, 'product_tax_code'> & Required<Pick<TaxLineItem, 'id' | 'quantity' | 'unit_price'>> => {
	const {
		[product]: {
			amount,
			productTaxCode,
			blank: { productTaxCode: productTaxCodeBlank = undefined } = {},
		},
	} = productsById;

	return {
		quantity,
		id:               getSkuName(sku),
		product_tax_code: productTaxCode ?? productTaxCodeBlank,
		unit_price:       amount / 100,
	};
};

export const sumShippoWeights = (parcels: Pick<Parcel, 'mass_unit' | 'weight'>[]): Pick<Parcel, 'mass_unit' | 'weight'> => flow(
	groupBy('mass_unit'),
	mapValues(sumBy('weight')),
	({
		kg = 0,
		lb = 0,
		g: gOriginal = 0,
		oz: ozOriginal = 0,
	}: {
		[index: string]: number | undefined;
	}): Pick<Parcel, 'mass_unit' | 'weight'> => {
		const g = (1000 * kg) + gOriginal;
		const oz = (16 * lb) + ozOriginal;

		const gRounded = Math.ceil(g * 10000) / 10000;
		const ozRounded = Math.ceil(oz * 10000) / 10000;

		return !g && !oz
			? {
				mass_unit: 'g',
				weight:    0,
			}
			: !g
				? ozRounded % 16 === 0
					? {
						mass_unit: 'lb',
						weight:    ozRounded / 16,
					}
					: {
						mass_unit: 'oz',
						weight:    ozRounded,
					}
				: !oz
					? gRounded % 1000 === 0
						? {
							mass_unit: 'kg',
							weight:    gRounded / 1000,
						}
						: {
							mass_unit: 'g',
							weight:    gRounded,
						}
					: {
						mass_unit: 'g',
						weight:    Math.ceil(((oz * 28.349523125) + g) * 10000) / 10000,
					};
	}
)(parcels);
