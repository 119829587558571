import firebase from 'firebase/app';
import { useMemo } from 'react';
import 'firebase/analytics';

export const useApp = (): firebase.app.App => useMemo(() => {
	if (!firebase.apps.length) {
		firebase.initializeApp({
			apiKey:            process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
			appId:             process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
			authDomain:        `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string}.firebaseapp.com`,
			databaseURL:       `https://${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string}.firebaseio.com`,
			messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
			projectId:         process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
			storageBucket:     `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string}.appspot.com`,
		});
	}

	return firebase.app();
}, []);
