import type { SanityBlock, SanityKeyed } from '@drop-party/sanity';
import type { SanityFileSource } from '@sanity/asset-utils';
import { getFile } from '@sanity/asset-utils';
import imageUrlBuilder from '@sanity/image-url';
import { compact, find, flow, groupBy, isArray, isNull, isPlainObject as isPlainObjectUntyped, map, mapValues, omitBy, reject } from 'lodash/fp';

import config from './config';

const isPlainObject = isPlainObjectUntyped as ((obj: any) => obj is Record<string, unknown>);

export const imageBuilder = imageUrlBuilder(config);

export const getFileUrl = (fileSource: SanityFileSource): string => getFile(fileSource, config).asset.url;

export const getPlainText = (blocks: SanityKeyed<SanityBlock>[]): string => blocks
	.map(({ children }: SanityKeyed<SanityBlock> & { children?: { text: string }[] }) => (children ?? [])
		.map((child) => child.text)
		.join(''))
	.join('\n\n');

export const removeNulls = <T = unknown>(obj: T): T => (
	isPlainObject(obj)
		? flow(
			omitBy(isNull),
			mapValues(removeNulls)
		)(obj)
		: isArray(obj)
			? flow(
				reject(isNull),
				map(removeNulls)
			)(obj)
			: obj
) as T;

export const prioritizeDraft = <T extends { _id: string }>(objs: T[]): T | undefined => find(({ _id }) => _id.startsWith('drafts.'), objs) ?? objs[0];

export const groupBySanityId = <T extends { _id: string }>(docs: (T | undefined)[]): { [_id: string]: T | undefined } => flow(
	compact,
	removeNulls,
	groupBy<T>(({ _id }) => _id),
	mapValues(prioritizeDraft)
)(docs);
