import 'firebase/analytics';
import firebase from 'firebase/app';
import { useRouter } from 'next/router';
import { useAsync } from 'react-use';

import { useApp } from 'vendors/firebase/hooks';

export const useAnalytics = (): firebase.analytics.Analytics | undefined => {
	const app = useApp();
	const router = useRouter();

	const routerExists = Boolean(router);

	const { value: isSupported } = useAsync(async () => firebase.analytics.isSupported(), []);

	return !process.browser || !isSupported || !routerExists ? undefined : firebase.analytics(app);
};
