import googleTagManagerPlugin from '@analytics/google-tag-manager';
import type { Account, Product } from '@drop-party/sanity';
import type { AnalyticsInstance, AnalyticsPlugin } from 'analytics';
import { Analytics } from 'analytics';
import doNotTrackPlugin from 'analytics-plugin-do-not-track';
import firebase from 'firebase/app';
import { find, sumBy, values } from 'lodash/fp';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import type { LineItem, Order, PotentialOrder } from 'models/orders';
import { getSkuName } from 'models/products';
import { useAnalytics as useFirebaseAnalytics } from 'vendors/firebase/analytics/hooks';
import { useFullStory } from 'vendors/fullstory/hooks';
import { useSentry } from 'vendors/sentry/hooks';

export type ProductFiltered = Pick<Product, '_id' | 'amount' | 'label'>;

const analyticsItemFromProduct = ({ amount, _id: item_id, label: item_name }: ProductFiltered): {
	currency: 'USD';
	item_id: string;
	item_name: string;
	price: number;
	quantity: 1;
} => ({
	item_id,
	item_name,
	quantity: 1,
	currency: 'USD',
	price:    amount / 100,
});

const analyticsItemFromLineItem = ({ quantity, sku }: LineItem): {
	item_variant: string;
	quantity: number;
} => ({
	quantity,
	item_variant: getSkuName(sku),
});

const analyticsItemsFromLineItems = (lineItems: LineItem[], products: ProductFiltered[]): {
	currency: 'USD';
	value: number;
	items: {
		currency: 'USD';
		item_id: string;
		item_name: string;
		item_variant: string;
		price: number;
		quantity: number;
	}[];
} => {
	const items = lineItems.map((lineItem) => ({
		...analyticsItemFromProduct(find({ _id: lineItem.sku.product }, products)!),
		...analyticsItemFromLineItem(lineItem),
	}));

	return {
		items,
		currency: 'USD',
		value:    sumBy(({ price, quantity }): number => quantity * price, items),
	};
};

export const useAnalytics = ({ account: { gtm } = {} }: { account?: Pick<Account, 'gtm'> } = {}): AnalyticsInstance => {
	const FS = useFullStory();
	const Sentry = useSentry();
	const firebaseAnalytics = useFirebaseAnalytics();

	const analytics = useMemo(() => Analytics({
		app:     'drop.party',
		plugins: !process.browser
			? []
			: [
				doNotTrackPlugin(),
				gtm && {
					...googleTagManagerPlugin({
						containerId:   gtm,
						dataLayerName: 'dropDataLayer',
					}),
					name: 'drop-specific-gtm',
				},
				{
					name:     'sentry-plugin',
					loaded:   (): boolean => true,
					identify: ({ payload: { userId: id } }: { payload: { userId: string } }): void => Sentry.setUser({ id }),
				},
				{
					name:   'fullstory-plugin',
					loaded: (): boolean => true,
					track:  ({ payload: { event, properties } }: { payload: { event: string; properties: Record<string, any> } }): void => FS.event(event, properties),
					identify({ payload: { traits, userId, anonymousId: analyticsAnonymousId_str } }: { payload: { anonymousId: string; traits: Record<string, any>; userId: string } }): void {
						if (userId) {
							FS.identify(userId, traits);
						} else {
							FS.setUserVars({
								...traits,
								analyticsAnonymousId_str,
							});
						}
					},
				},
				process.env.NODE_ENV === 'development' && {
					name:       'console-plugin',
					loaded:     (): boolean => true,
					identify:   ({ payload }: { payload: any }): void => console.log('analytics identify', payload), // eslint-disable-line no-console -- Specifically made for console
					initialize: ({ payload }: { payload: any }): void => console.log('analytics initialize', payload), // eslint-disable-line no-console -- Specifically made for console
					page:       ({ payload }: { payload: any }): void => console.log('analytics page', payload), // eslint-disable-line no-console -- Specifically made for console
					track:      ({ payload }: { payload: any }): void => console.log('analytics track', payload), // eslint-disable-line no-console -- Specifically made for console
				},
				firebaseAnalytics && {
					name:     'firebase-analytics-plugin',
					loaded:   (): boolean => Boolean(firebaseAnalytics),
					identify: ({ payload: { userId } }: { payload: { userId: string } }): void => firebaseAnalytics.setUserId(userId),
					page:     ({ payload: { properties: { path } } }: { payload: { properties: { path: string } } }): void => firebaseAnalytics.setCurrentScreen(path),
					track:    ({ payload: { event, properties } }: { payload: { event: string; properties: Record<string, any> } }): void => firebaseAnalytics.logEvent(event, properties),
				},
			].filter((plugin: AnalyticsPlugin | undefined | null | false | ''): plugin is NonNullable<AnalyticsPlugin> => Boolean(plugin)),
	}), [
		FS,
		Sentry,
		firebaseAnalytics,
		gtm,
	]);

	const router = useRouter();
	const routerExists = Boolean(router);
	const path = routerExists ? router.asPath : undefined;

	useEffect(() => {
		if (!path) {
			return;
		}
		void analytics.page();
	}, [analytics, path]);

	return analytics;
};

export const addPaymentInfoEvent = async (analytics: AnalyticsInstance, { transactions: { data: transactions } }: Order, products: ProductFiltered[]): Promise<any> => Promise.all<any>(
	values(transactions).map(async ({ lineItems, total }) => analytics.track(
		firebase.analytics.EventName.ADD_PAYMENT_INFO,
		{
			...analyticsItemsFromLineItems(lineItems, products),
			value: total / 100,
		}
	))
);

export const addShippingInfoEvent = async (analytics: AnalyticsInstance, { transactions: { data: transactions } }: PotentialOrder, products: ProductFiltered[]): Promise<any> => Promise.all<any>(
	values(transactions).map(async ({ lineItems, total }) => analytics.track(
		firebase.analytics.EventName.ADD_SHIPPING_INFO,
		{
			...analyticsItemsFromLineItems(lineItems, products),
			value: total / 100,
		}
	))
);

export const addToCartEvent = async (analytics: AnalyticsInstance, lineItem: LineItem, product: ProductFiltered): Promise<any> => analytics.track(firebase.analytics.EventName.ADD_TO_CART, analyticsItemsFromLineItems([lineItem], [product]));

export const beginCheckoutEvent = async (analytics: AnalyticsInstance, lineItems: LineItem[], products: ProductFiltered[]): Promise<any> => analytics.track(firebase.analytics.EventName.BEGIN_CHECKOUT, analyticsItemsFromLineItems(lineItems, products));

export const purchaseEvent = async (analytics: AnalyticsInstance, { id: transaction_id, transactions: { data: transactions } }: Order, products: ProductFiltered[]): Promise<any> => Promise.all<any>(
	values(transactions).map(async ({ lineItems, shipping, tax, total }) => analytics.track(
		firebase.analytics.EventName.PURCHASE,
		{
			...analyticsItemsFromLineItems(lineItems, products),
			shipping,
			tax,
			transaction_id,
			value: total / 100,
		}
	))
);

export const removeFromCartEvent = async (analytics: AnalyticsInstance, lineItem: LineItem, products: ProductFiltered[]): Promise<any> => analytics.track(firebase.analytics.EventName.REMOVE_FROM_CART, analyticsItemsFromLineItems([lineItem], products));

export const viewItemEvent = async (analytics: AnalyticsInstance, product: ProductFiltered): Promise<any> => analytics.track(
	firebase.analytics.EventName.VIEW_ITEM,
	{
		currency: 'USD',
		items:    [analyticsItemFromProduct(product)],
		value:    product.amount / 100,
	}
);
