import type { Blank, Product, SanityKeyed, Variation, VariationGrouped } from '@drop-party/sanity';
import { compact, conformsTo, every, find, flatMap, flow, isPlainObject, isString, join, overEvery, map, reduce, sortBy, toPairs } from 'lodash/fp';

import { cross } from 'utils';

type IsSKU = (sku: unknown) => sku is SKU;

export interface SKU {
	product: string;
	variations: {
		[variationName: string]: string;
	};
}

export const isSKU: IsSKU = conformsTo({
	product:    isString,
	variations: overEvery([isPlainObject, every(isString)]),
}) as IsSKU;

export const getSkuName = ({ product, variations }: SKU): string => flow(
	compact,
	join('--')
)([
	product,
	flow(
		toPairs,
		sortBy('0'),
		map('1'),
		join('--')
	)(variations),
]);

export const getReadableSkuName = ({ skuName: { current: skuName } }: Pick<Product, 'skuName'>) => ({ variations }: Pick<SKU, 'variations'>): string => flow(
	compact,
	join('--')
)([
	skuName,
	flow(
		toPairs,
		sortBy('0'),
		map('1'),
		join('--')
	)(variations),
]);

export const getAllSKUs = ({
	_id:   product,
	blank: { variations = [] } = {},
}: Pick<Product, '_id'> & { blank?: Pick<Blank, 'variations'> }): SKU[] => flow(
	map(({ skuName: { current: variationName }, ...variation }: SanityKeyed<Variation> | SanityKeyed<VariationGrouped>) => (
		'groups' in variation
			? flatMap(
				({ variants, skuName: { current: groupSkuName } }) => variants.map(({ skuName: { current: variantSkuName } }) => ({ [variationName]: `${groupSkuName}-${variantSkuName}` })),
				variation.groups
			)
			: variation.variants.map(({ skuName: { current: variantSkuName } }) => ({ [variationName]: variantSkuName }))
	)),
	reduce((acc, assignments) => cross((val: { [variationName: string]: string }, val2: { [variationName: string]: string }): { [variationName: string]: string } => ({
		...val,
		...val2,
	}), acc)(assignments), [{}] as { [variationName: string]: string }[]),
	map((variations): SKU => ({
		product,
		variations,
	}))
)(variations);

export const getVariationName = (variations: NonNullable<Blank['variations']>, skuVariations: SKU['variations']): string => variations
	.map((variation): string => {
		if (!('groups' in variation)) {
			return find(
				{ skuName: { current: skuVariations[variation.skuName.current] } },
				variation.variants
			)!.label;
		}

		const [variationSkuName, ...variantSkuPieces] = skuVariations[variation.skuName.current].split('-');

		return find(
			{ skuName: { current: variantSkuPieces.join('-') } },
			find(
				{ skuName: { current: variationSkuName } },
				variation.groups
			)!.variants
		)!.label;
	})
	.join(', ');
