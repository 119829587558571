import * as FullStory from '@fullstory/browser';

if (process.browser) {
	FullStory.init({
		debug:   process.env.NODE_ENV !== 'production',
		devMode: !process.env.NEXT_PUBLIC_FULLSTORY_ORG,
		orgId:   process.env.NEXT_PUBLIC_FULLSTORY_ORG ?? 'dev',
	});
}

export const useFullStory = (): typeof FullStory => FullStory;
