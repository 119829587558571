import type { PaymentRequestShippingAddress } from '@stripe/stripe-js';
import { compact, conformsTo, every, flatMap, flow, isArray, isEmpty, isString, keys, map, negate, overEvery } from 'lodash/fp';
import type { FunctionComponent } from 'react';

export type UnArray<T> = T extends (infer S)[] ? S : T extends readonly (infer S)[] ? S : never;

export type UnObject<T> = T extends { [key: string]: infer S } ? S : never;

export const hasOwnProperty = <T extends unknown, K extends string>(x: unknown, key: K): x is { [M in K]: T } => x instanceof Object && key in x;

type ConformsToOptional = (predicateObject: { [key: string]: (arg: any) => boolean }) => (object: any) => boolean;

// TODO I don't like how I did conformsToOptional
export const conformsToOptional: ConformsToOptional = (predicateObject) => (object) => Boolean(object) && flow(
	keys,
	every((key) => !hasOwnProperty(object, key) || predicateObject[key](object[key]))
)(predicateObject);

export type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends (infer I)[]
		? DeepPartial<I>[]
		: T[P] extends FunctionComponent
			? T[P]
			: DeepPartial<T[P]>
};

export const cross = <T = any, S = any, R = any>(fn: (s: S, t: T) => R, array1: S[]) => (array2: T[]): R[] => flatMap((s: S) => map((t: T) => fn(s, t), array2), array1);

const numberFormat = new Intl.NumberFormat('en-US', {
	currency: 'USD',
	style:    'currency',
});

export const formatCurrency = (amount: number): string => numberFormat.format(amount);

export type PartialShippingAddress = Required<Pick<PaymentRequestShippingAddress, 'city' | 'country' | 'postalCode' | 'region'>>;

type IsPartialShippingAddress = (shippingAddress: unknown) => shippingAddress is PartialShippingAddress;

export const isPartialShippingAddress: IsPartialShippingAddress = conformsTo({
	city:       isString,
	country:    isString,
	postalCode: isString,
	region:     isString,
}) as IsPartialShippingAddress;

export type ShippingAddress = Required<Pick<PaymentRequestShippingAddress, 'addressLine' | 'recipient'>> & PartialShippingAddress;

type IsShippingAddress = (shippingAddress: unknown) => shippingAddress is ShippingAddress;

export const isShippingAddress: IsShippingAddress = overEvery([
	isPartialShippingAddress,
	conformsTo({
		recipient:   isString,
		addressLine: overEvery([
			isArray,
			flow(
				compact,
				overEvery([negate(isEmpty), every(isString)])
			),
		]),
	}),
]) as IsShippingAddress;

export const contrast = (hex: string): 'dark' | 'light' => {
	const bigint = Number.parseInt(hex.slice(1), 16);

	/* eslint-disable no-bitwise,radix -- Copied From https://github.com/scottcorgan/contrast/blob/master/index.js */
	// @ts-expect-error -- Copied From https://github.com/scottcorgan/contrast/blob/master/index.js
	return Math.round(((Number.parseInt((bigint >> 16) & 255) * 299) + (Number.parseInt((bigint >> 8) & 255) * 587) + (Number.parseInt(bigint & 255) * 114)) / 1000) > 180 ? 'dark' : 'light';
	/* eslint-enable no-bitwise, radix -- Copied From https://github.com/scottcorgan/contrast/blob/master/index.js */
};
