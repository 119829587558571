import SentryFullStory from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import config from 'vendors/sentry/config';

if (process.browser) {
	Sentry.init({
		...config,
		// TODO Get sentry org from env variable, currently sentry sets SENTRY_ORG not NEXT_PUBLIC_SENTRY_ORG
		integrations: [...config.integrations, new SentryFullStory('drops'), new Integrations.BrowserTracing()],
	});
}

export const useSentry = (): typeof Sentry => Sentry;
